<template>
<div class="section" v-if="getSettingUserAuthorized('payrollAuthorizedEmails')">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>
<div class="box is-danger" v-else><div class="hero title">Not authorized</div></div>
</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
// import { PageSizes } from 'pdf-lib'
// import { degrees } from 'pdf-lib'
// import { rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../store/UserStore"
// import { text } from 'body-parser'

export default {
    props: {
        isShowPDFDialog: Boolean,
        groupedPayroll: [],
        totalHours: Number,
        totalPayroll: Number,
        totalHourlyPay: Number,
        totalNonHourlyPay: Number,
        payrollBudgetedHours: Object,
        dateRange: []
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            pageNumber: 0,
            page: {},
            isShowPDFDialogLocal: false,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}),
            settingsValues: UserStore.getters.getStoreSettingsValues,
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            // if (!this.getSetting('showCommission') &&  !this.getSettingUserAuthorized('settingsAuthorizedEmails')) {
            //     return
            // }
            
            gthis.pdfDoc = await PDFDocument.create()
            // const pdfDoc = gthis.pdfDoc
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Payroll Report')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(gthis)
            var text = ""
            var row = 680
            row -= 10
            gthis.groupedPayroll.forEach(week => {
                if (row < 80) {
                    gthis.pageHeader(gthis)
                    row = 680
                }
                row -= 20
                gthis.drawColHeadLeft(gthis, row, 30, 10, "Pay Period")
                gthis.drawColHeadLeft(gthis, row, 140, 10, "Payment Offset")
                gthis.drawColHeadRight(gthis, row, 300, 10, "Budget")
                gthis.drawColHeadRight(gthis, row, 350, 10, "Hours")
                gthis.drawColHeadRight(gthis, row, 420, 10, "Hourly")
                gthis.drawColHeadRight(gthis, row, 500, 10, "Non-Hourly")
                gthis.drawColHeadRight(gthis, row, 570, 10, "Total Amount")
                row -= 20
                gthis.drawTextLeft(gthis, row, 30, 10, moment(week.PayPeriodBeg).format('MM/DD/YYYY') + "-" + moment(week.PayPeriodEnd).format('MM/DD/YYYY'))
                gthis.drawTextLeft(gthis, row, 140, 10, moment(week.PayPeriodOffsetBeg).format('MM/DD/YYYY') + "-" + moment(week.PayPeriodOffsetEnd).format('MM/DD/YYYY'))
                gthis.drawTextRight(gthis, row, 300, 10, gthis.payrollBudgetedHours.value.toFixed(2))
                gthis.drawTextRight(gthis, row, 350, 10, week.PayPeriodHours.toFixed(2))
                gthis.drawTextRightCurrency(gthis, row, 420, 10, week.PayPeriodHourlyAmount.toFixed(2))
                gthis.drawTextRightCurrency(gthis, row, 500, 10, week.PayPeriodNonHourlyAmount.toFixed(2))
                gthis.drawTextRightCurrency(gthis, row, 570, 10, week.PayPeriodAmount.toFixed(2))
                row -= 5
                gthis.page.drawLine({
                    start: { x: 30, y: row },
                    end: { x: 570, y: row },
                    thickness: 1,
                    opacity: 0.2,
                })
                gthis.pageSubHead(gthis,row)
                row -= 20
                week.PayPeriodPayments.forEach(payroll => {
                    row = row - 20
                    gthis.drawTextLeft(gthis, row, 55, 10, moment(payroll.shiftDate).format('MM/DD/YYYY'))
                    gthis.drawTextLeft(gthis, row, 150, 10, gthis.toTitleCase(payroll.employeeName))
                    gthis.drawTextLeft(gthis, row, 300, 10, payroll.payments[0].method)
                    gthis.drawTextLeft(gthis, row, 350, 10, payroll.expenseTypeName)
                    gthis.drawTextRight(gthis, row, 470, 10, payroll.employeePayrollHours.toFixed(2).toString())
                   

                    text = payroll.amount.toString()
                    gthis.drawTextRightCurrency(gthis, row, 550, 10, text)
                    if (row < 80) {
                        gthis.pageHeader(gthis)
                        row = 680
                    }
                })

                row -= 20

                gthis.page.drawLine({
                    start: { x: 30, y: row },
                    end: { x: 570, y: row },
                    thickness: 1,
                    opacity: 0.2,
                })
                
            })

            // Grand Total
            row -= 30
            gthis.page.drawLine({
                start: { x: 30, y: row },
                end: { x: 570, y: row },
                thickness: 1,
                opacity: 0.4,
            })

            row -= 15
            gthis.drawColHeadRight(gthis, row, 250, 10, "Budget")
            gthis.drawColHeadRight(gthis, row, 310, 10, "Hours")
            gthis.drawColHeadRight(gthis, row, 400, 10, "Hourly")
            gthis.drawColHeadRight(gthis, row, 480, 10, "Non-Hourly")
            gthis.drawColHeadRight(gthis, row, 570, 10, "Total Amount")

            row -= 20
            gthis.drawRowHeadTextLeft(gthis, row, 55, 12, 'Total Payroll: ')
            gthis.drawTextRight(gthis, row, 250, 12, (gthis.payrollBudgetedHours.value * gthis.groupedPayroll.length).toFixed(2).toString())
            gthis.drawTextRight(gthis, row, 310, 12, gthis.totalHours.toFixed(2).toString())
            gthis.drawTextRightCurrency(gthis, row, 400, 12, gthis.totalHourlyPay.toFixed(2).toString())
            gthis.drawTextRightCurrency(gthis, row, 480, 12, gthis.totalNonHourlyPay.toFixed(2).toString())
            gthis.drawTextRightCurrency(gthis, row, 570, 12, gthis.totalPayroll.toFixed(2).toString())

            row -= 10
            gthis.page.drawLine({
                start: { x: 30, y: row },
                end: { x: 570, y: row },
                thickness: 2,
                opacity: 0.4,
            })

            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "PAYROLL REPORT"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 14, 
                })

            var row = 725
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 15);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 700
            var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
            textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);

            gthis.page.drawText(dateRange, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })

            await gthis.pageFooter(gthis)                         
                
        },

        pageSubHead: async(gthis,row) => {
            row -= 25
            gthis.drawColHeadLeft(gthis, row, 55, 12, 'Date')
            gthis.drawColHeadLeft(gthis, row, 150, 12, 'Employee Name')
            gthis.drawColHeadLeft(gthis, row, 300, 12, 'Paid by')
            gthis.drawColHeadLeft(gthis, row, 350, 12, 'Type')
            gthis.drawColHeadRight(gthis, row, 470, 12, 'Hours')
            gthis.drawColHeadRight(gthis, row, 550, 12, 'Paid Amount')
        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },

        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 75) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
            if (text === "InsideSales") { text = "Inside Sales"}
            if (text === "BeerWine") { text = "Beer / Wine"}
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawColHeadLeft:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart, y: row },
                end: { x: colStart+textWidth+40, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 20, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
            gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row) => {
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.page.drawLine({
                start: { x: 45, y: row },
                end: { x: 500, y: row },
                thickness: 1,
                opacity: 0.2,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75,
            })
        },

        async loadData() {
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
            this.pageNumber = 0
        },
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>