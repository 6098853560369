<template>
    <div class="panel">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-10 has-text-centered">PAYROLL REPORT</div>
                <div class="column has-text-right is-2">
                    <b-button title="Export to Excel" v-if="getSettingUserAuthorized('settingsAuthorizedPayroll')" type="is-primary" outlined class="is-small is-hidden-mobile" @click="jsonAsXlsx">
                        <!-- <img src="/images/spreadsheet.png" width="20" height="20" alt="" class="is-rounded"> -->
                        <i class="fas fa-file-excel"></i>
                    </b-button>
                    
                    <b-button title="Print" v-if="getSettingUserAuthorized('settingsAuthorizedPayroll')" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div>
            </div>
        </div>

        <hr>

        <div class="columns">
            <div class="column is-4 has-text-left">
                <b-field>
                    <date-range-selector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>                    
                </b-field>                    
            </div>
            <div class="column is-3">
                <div>
                    <p class="is-size-7">Pay Week Start Day</p>
                    <p class="is-size-6" > {{ (payrollWeekBeginDayNumber.value ? dayOfWeekAsString(payrollWeekBeginDayNumber.value) : dayOfWeekAsString(0))  }}</p>
                </div>
            </div>
            <div class="column is-2">
                <div>
                    <p class="is-size-7">Payment Offset days</p>
                    <p class="is-size-6" > {{ parseInt(payrollNumDaysAfterPayPeriodToPay.value ? payrollNumDaysAfterPayPeriodToPay.value : 0)  }}</p>
                </div>
            </div>
            <div class="column is-3">
                <div>
                    <p class="is-size-7">Weekly Budgeted Hours</p>
                    <p class="is-size-6" > {{ parseInt(payrollBudgetedHours.value ? payrollBudgetedHours.value : 0)  }}</p>
                </div>
            </div>
        </div>
        
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered" v-if="payrollBudgetedHours.value">
                    <div>
                    <p class="is-size-7">Total Budgeted Hours</p>
                    <p class="is-size-6" :class="{'has-text-danger': payrollBudgetedHours.value < 0.00 }"> 
                        {{ (payrollBudgetedHours.value ? (payrollBudgetedHours.value * weeksInDateRange.length) : 0)| formatNumber}}
                    </p>
                    </div>
                </div> 

                <div class="column has-text-centered">
                    <div :class="{'has-text-danger': totalHours > (payrollBudgetedHours.value * weeksInDateRange.length) }">
                    <p class="is-size-7">Total Actual Hours</p>
                    <p class="is-size-6" > {{ totalHours| formatNumber }}</p>
                    </div>
                </div>  

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Hourly Pay</p>
                    <p class="is-size-6" :class="{'has-text-danger': (totalHourlyPay)  < 0.00 }"> {{ totalHourlyPay | formatCurrency }}</p>
                    </div>
                </div>

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Non-Hourly Pay</p>
                    <p class="is-size-6" :class="{'has-text-danger': (totalNonHourlyPay)  < 0.00 }"> {{ totalNonHourlyPay | formatCurrency }}</p>
                    </div>
                </div>

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Amount</p>
                    <p class="is-size-6" :class="{'has-text-danger': (totalPayroll)  < 0.00 }"> {{ totalPayroll | formatCurrency }}</p>
                    </div>
                </div>
            </div>
        </div>

        <b-table
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='weeksInDateRange'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="payrollDate"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"
            detailed
            detail-key="PayPeriodBeg"            
            >
            <template>
                <b-table-column field="PayPeriodBeg" label="Pay Period" v-slot="props">
                    {{ props.row.PayPeriodBeg | formatDate }} - {{ props.row.PayPeriodEnd | formatDate }}
                </b-table-column>
                <b-table-column field="weekOffsetBeg" label="Payment Date Range" v-slot="props">
                    {{ props.row.PayPeriodOffsetBeg | formatDate }} - {{ props.row.PayPeriodOffsetEnd | formatDate }}
                </b-table-column>
                <b-table-column field="budget" label="Weekly Budgeted Hours">
                    {{ payrollBudgetedHours.value | formatNumber }}
                </b-table-column>
                <b-table-column numeric field="PayPeriodHours" label="Hours" v-slot="props">
                    <span :class="{'has-text-danger': props.row.PayPeriodHours > payrollBudgetedHours.value }">{{ props.row.PayPeriodHours | formatNumber}}</span>
                </b-table-column>
                <b-table-column numeric field="PayPeriodHours" label="Hourly Amount" v-slot="props">
                    {{ props.row.PayPeriodHourlyAmount | formatCurrency}}
                </b-table-column>
                <b-table-column numeric field="PayPeriodHours" label="Non-Hourly Amount" v-slot="props">
                    {{ props.row.PayPeriodNonHourlyAmount | formatCurrency}}
                </b-table-column>
                <b-table-column numeric field="PayPeriodAmount" label="Amount" v-slot="props">
                    {{ props.row.PayPeriodAmount | formatCurrency }}
                </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.PayPeriodPayments">
                <template>
                    <b-table-column field="shiftDate" label="Payroll Date" v-slot="props">
                        {{ props.row.shiftDate |formatDate}}
                    </b-table-column>
                    <b-table-column field="employeeName" label="Employee" v-slot="props">
                        {{ toTitleCase(props.row.employeeName) }}
                    </b-table-column>
                    <b-table-column field="employeeName" label="Payroll Type" v-slot="props">
                        {{ toTitleCase(props.row.expenseTypeName) }}
                    </b-table-column>
                    <b-table-column field="employeePayrollHours" label="Payment Method" v-slot="props">
                        {{ props.row.payments[0].method }}
                    </b-table-column>                    
                    <b-table-column numeric field="employeePayrollHours" label="Hours" v-slot="props">
                        {{ props.row.employeePayrollHours | formatNumber }}
                    </b-table-column>
                    <b-table-column numeric field="amount" label="Amount" v-slot="props" sortable sorticon>
                        {{ props.row.amount | formatCurrency}}
                    </b-table-column>
                </template>
              </b-table>
            </template>

        </b-table>

        <payroll-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :dateRange="salesDateRange"
            :groupedPayroll="weeksInDateRange"
            :payrollBudgetedHours="payrollBudgetedHours"
            :totalHours="totalHours"
            :totalHourlyPay="totalHourlyPay"
            :totalNonHourlyPay="totalNonHourlyPay"
            :totalPayroll="totalPayroll"
            @close-pdf-dialog="togglePDFDialog"
        ></payroll-pdf>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DateRangeSelector from '../../../components/app/DateRangeSelector.vue'
import UserStore from '../../../store/UserStore'
import PayrollPdf from './PayrollPDF.vue'
import jsonAsXLSX from 'json-as-xlsx'
export default {

    components: {
        DateRangeSelector,
        PayrollPdf
    },

    data() {
        return {
            salesDateRange: [moment().startOf('month').toDate(), new Date],
            page: 0,
            perPage: 25,
            sortField: 'sale.closingDate',
            sortOrder: 'desc',
            user: UserStore.getters.getUser,
            groupedPayroll: [],
            payrollRecords: [],
            weeksInDateRange: [],
            payrollBudgetedHours: UserStore.getters.getStoreSettingsValue('payrollBudgetedHours'),
            payrollNumDaysAfterPayPeriodToPay: UserStore.getters.getStoreSettingsValue('payrollNumDaysAfterPayPeriodToPay'),
            isLoading: false,
            isScrollable: false,
            isPaginated: true,
            showDetailIcon: true,
            totalHours: 0,
            totalPayroll: 0,
            totalHourlyPay: 0,
            totalNonHourlyPay: 0,
            totalSalaryPay: 0,
            isShowPDFDialog: false,
            payrollWeekBeginDayNumber: UserStore.getters.getStoreSettingsValue('payrollWeekBeginDayNumber'),
        }
    },

    methods: {
        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        setWeeksInDateRange() {
            var weekStartDayNumber = parseInt(this.payrollWeekBeginDayNumber.value)
            if (weekStartDayNumber == null) {
                weekStartDayNumber = 0
            }
            var weekEndDayNumber = parseInt(this.payrollWeekBeginDayNumber.value - (weekStartDayNumber > 0 ? weekStartDayNumber - 1 : 0))
            var firstPayrollDayOfPeriod = moment(this.salesDateRange[0]).isoWeekday(weekStartDayNumber)
            var lastPayrollDayOfPeriod = moment(this.salesDateRange[1]).isoWeekday(weekEndDayNumber)
            // var curDate = moment(this.salesDateRange[0])
            var curDate = moment(firstPayrollDayOfPeriod)
            
            
            this.weeksInDateRange = []
            // while (curDate.isBefore(lastPayrollDayOfPeriod, "day")) {
            while (curDate <= lastPayrollDayOfPeriod) {

                var weekBeg = curDate.toDate()
                var weekEnd = moment(weekBeg).add('6','days').toDate()

                var weekOffsetBeg = moment(weekBeg).add(this.payrollNumDaysAfterPayPeriodToPay.value, 'days').toDate()
                var weekOffsetEnd = moment(weekEnd).add(this.payrollNumDaysAfterPayPeriodToPay.value, 'days').toDate()

                if (moment(weekEnd) > moment(this.salesDateRange[1])) {
                    // console.log (moment(weekBeg).format("YYYY-MM-DD"), ">", moment(this.salesDateRange[1]).format("YYYY-MM-DD"))
                    return
                // } else {
                //     console.log (moment(weekBeg).format("YYYY-MM-DD"), "<=", moment(this.salesDateRange[1]).format("YYYY-MM-DD"))
                }

                var week = { 
                    "PayPeriodBeg": weekBeg,
                    "PayPeriodEnd": weekEnd,
                    "PayPeriodOffsetBeg": weekOffsetBeg,
                    "PayPeriodOffsetEnd": weekOffsetEnd,
                    "PayPeriodHours": 0.00,
                    "PayPeriodHourlyAmount": 0.00,
                    "PayPeriodNonHourlyAmount": 0.00,
                    "PayPeriodAmount": 0.00,
                    "PayPeriodPayments": []
                }
                // console.log("week",week)
                this.weeksInDateRange.push(week)
                curDate=moment(weekBeg).add("7","days")
            }
        },

        groupByWeek() {

            var sortedPayroll = this.payrollRecords.sort((b,a) =>  moment(b.shiftDate).toDate()- moment(a.shiftDate).toDate() )
            this.groupedPayroll = []
            this.totalHours = 0
            this.totalPayroll = 0
            this.totalHourlyPay = 0
            this.totalNonHourlyPay = 0
            this.totalSalaryPay = 0

            sortedPayroll.forEach(item => {
                item.method = item.payments[0].method
                this.weeksInDateRange.forEach(week => {
                    var periodBeg = week.PayPeriodBeg
                    var periodEnd = week.PayPeriodEnd
                    if (this.payrollNumDaysAfterPayPeriodToPay.value > 0) {
                        periodBeg = moment(week.PayPeriodBeg).add(this.payrollNumDaysAfterPayPeriodToPay.value, 'days').toDate()
                        periodEnd = moment(week.PayPeriodEnd).add(this.payrollNumDaysAfterPayPeriodToPay.value, 'days').toDate()
                    }
                    if (moment(item.shiftDate).isBetween(moment(periodBeg), moment(periodEnd), null, '[]')) {
                        week.PayPeriodHours += item.employeePayrollHours
                        if (week.PayPeriodHours > 0) {
                            this.totalHourlyPay += item.amount
                            week.PayPeriodHourlyAmount += item.amount
                        } else {
                            this.totalNonHourlyPay += item.amount
                            week.PayPeriodNonHourlyAmount += item.amount
                        }
                        this.totalHours += item.employeePayrollHours
                        this.totalPayroll += item.amount
                        week.PayPeriodAmount += item.amount
                        week.PayPeriodPayments.push(item)
                    // } else {
                    //     console.log(moment(item.shiftDate).format("YYYY-MM-DD"), moment(week.PayPeriodBeg).format("YYYY-MM-DD"), moment(week.PayPeriodEnd).format("YYYY-MM-DD"))
                    }
                })
            })
        },

        groupByDate() {

            var sortedPayroll = this.payrollRecords.sort((b,a) =>  moment(b.shiftDate).toDate()- moment(a.shiftDate).toDate() )
            var currentPayrollDate = ""
            this.groupedPayroll = []
            this.totalHours = 0
            this.totalPayroll = 0
            sortedPayroll.forEach((item) => {
                this.totalPayroll += item.amount
                this.totalHours += item.employeePayrollHours
                item.method = item.payments[0].method
                if (item.shiftDate === currentPayrollDate) {
                    this.groupedPayroll[this.groupedPayroll.length-1].payroll.push(item)
                } else {
                    currentPayrollDate = item.shiftDate
                    var group = {}
                    group.payrollDate = moment(item.shiftDate).toDate()
                    group.payroll = [item]
                    group.weekIndex = this.groupedPayroll.length + 1
                    this.groupedPayroll.push(group)
                }
                
            })

            this.groupedPayroll.forEach(item => {
                // var sorted = item.payroll.sort((a,b) => a.employeeName - b.employeeName)
                var sorted = this.sortArrayByProp(item.payroll, 'employeeName', 'asc')
                item.payroll = sorted
            })

        },

        async fetchRecords() {
            this.payrollRecords = []
            for (const item of UserStore.getters.getPayrollExpenseTypeIds) {
                await this.fetchPayroll(item)
            }
            
            // this.groupByDate()
            this.groupByWeek()

        },

        async fetchPayroll(typeId) {
            this.weeksInDateRange = []

            var startDate = this.salesDateRange[0].toISOString().split('T')[0]
            var endDate = this.salesDateRange[1].toISOString().split('T')[0]
            this.setWeeksInDateRange()
            // this.payrollRecords = []
            if (this.weeksInDateRange.length > 0) {
                if (this.payrollNumDaysAfterPayPeriodToPay.value > 0) {
                    startDate = this.weeksInDateRange[0].PayPeriodOffsetBeg.toISOString().split('T')[0]
                    endDate = this.weeksInDateRange[this.weeksInDateRange.length-1].PayPeriodOffsetEnd.toISOString().split('T')[0]
                    
                }
                // console.log(startDate,endDate)
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/expenses/search?"

                var showPage = this.page - 1
                if (this.isShowPDFDialog) {
                    url = url + "page=0&size=1M"
                } else {
                    this.perPage = this.setPerPage
                    url = url + "page="+showPage+"&size=" + this.perPage
                } 
                if (this.sortField == "") {
                    this.sortField = "sale.closingDate"
                }
                if (this.sortOrder == "") {
                    this.sortOrder = "desc"
                } 
                url = url + "&sort="+this.sortField+","+this.sortOrder
                url = url + "&expenseTypeId="+typeId
                url = url + "&expenseDateFrom="+startDate+"&expenseDateTo="+endDate

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.payrollRecords.push.apply(this.payrollRecords, response.data.data.expenses.content);
                    })
            }   

        },

        jsonAsXlsx() {
            var jsonAsXlsxData = [
                {
                    sheet: "Payroll Report",
                    columns: [
                    { label: "Date", value: "shiftDate", format: "MM/DD/YYYY" },
                    { label: "Name", value: "employeeName" },
                    { label: "Name", value: "expenseTypeName" },
                    { label: "Paid by", value: "method" },
                    { label: "Hours", value: "employeePayrollHours", format: "#,##0.00" },
                    { label: "Amount", value: "amount", format: "$#,##0.00"}, 
                    
                    ],
                    content: this.payrollRecords,
                }]
                
            let settings = {
                fileName: "VEECLi-DailySales-" + moment().format('YYYY-MM-DD-HH-mm-ss'), // Name of the resulting spreadsheet
                extraLength: 1, // A bigger number means that columns will be wider
                writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
                writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
                RTL: false, // Display the columns from right-to-left (the default value is false)
            }

            jsonAsXLSX(jsonAsXlsxData, settings) // Will download the excel file

        },

        dayOfWeekAsString(dayIndex) {
            return ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][dayIndex] || '';
        },

    },

    computed: {
        

    },

    watch: {
        salesDateRange() {
            this.fetchRecords()
        }
    },

    mounted() {
        this.fetchRecords()
    }
    
}
</script>